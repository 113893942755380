import { EditOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { NotificationManager } from 'react-notifications';

import BaseContainer from '../../../../containers/BaseContainer';
import Bar from '../../../../elements/Bar';
import List from '../../../../elements/List';
import EtapeList from './EtapeList';
import EtapeModal from './modals/EtapeModal';

const detailData = ({ street, locality, sub_locality, postal_code } = {}) => {
  return [
    { name: 'Obec', value: locality ?? '' },
    { name: 'Část obce', value: sub_locality ?? '' },
    { name: 'Ulice', value: street ?? '' },
    { name: 'PSČ', value: postal_code ?? '' },
  ];
};

export default function BuildingDetailView({
  data,
  makeEditable,
  updateEtape,
  permission,
  updateDetail,
}) {
  const [visible, setVisible] = useState(false);
  const [currentEtape, setCurrentEtape] = useState(null);
  const handleEditEtape = (id) => {
    return <EditOutlined onClick={() => getEtapeDetail(id)} />;
  };

  const getEtapeDetail = (id) => {
    let tmp = (data?.etape_list || []).find((ele) => ele.id === id);
    if (tmp) {
      setCurrentEtape(tmp);
    }

    setVisible(true);
  };

  const submitEditedValues = async (values) => {
    try {
      await updateEtape(currentEtape.id, values);
      setVisible(false);
    } catch (e) {
      NotificationManager.error('Chyba', 'Omlouváme se, něco se pokazilo');
    }
  };

  return (
    <div>
      <BaseContainer fullHeight={true} width={'calc(100% - 420px)'} margin>
        <Bar
          text={'Adresní místo stavby'}
          content={
            permission === 2 && data ? [<EditOutlined key={1} onClick={makeEditable} />] : []
          }
        />
        <List data={detailData(data.identity_data || {})} />
        <Bar
          text={'Definiční bod'}
          content={
            permission === 2 && data ? [<EditOutlined key={1} onClick={makeEditable} />] : []
          }
        />
        {data && (
          <List
            data={[
              {
                name: 'Bod',
                value: `${data.latitude || ''} ${data.longitude || ''}`,
              },
            ]}
          />
        )}
        <Bar
          text={'Životní etapy stavby'}
          content={
            permission === 2 && data ? [<EditOutlined key={1} onClick={makeEditable} />] : []
          }
        />
        {data && <EtapeList data={data?.etape_list || []} actions={[handleEditEtape]} />}
      </BaseContainer>
      <EtapeModal
        visible={visible}
        onClose={(withReset) => {
          if (withReset) {
            updateDetail();
          }
          setVisible(false);
        }}
        data={[]}
        submitForm={submitEditedValues}
        formProps={currentEtape}
        title="Etapa"
      />
    </div>
  );
}
