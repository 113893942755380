import { Field, Form, Formik } from 'formik';
import { useRef } from 'react';
import Yup from '../../utills/myYup';
import Modal from '../../shared/modal/Modal';
import ModalButton from '../../shared/modal/ModalButton';
import Label from '../Label';
import { SelectField } from '../../shared/SelectField';
import { AreaField } from '../../shared/AreaField';
import { InstructionTypes } from './constants';
import { useQuery } from 'react-query';
import { apiService } from '../../utills/api';

const INSTRUCTIONS_WITH_CONTENT = ['HEA', 'TEX', 'DSC', 'CN'];

const InstructionDialog = ({ visible, onClose, formProps, onSubmit }) => {
  const formRef = useRef(null);

  const { data, error } = useQuery({
    queryKey: ['instructions-types'],
    queryFn: async () => {
      const { data } = await apiService.get(`/api/v1/instruction-template/instruction/`);

      return data;
    },
    refetchOnWindowFocus: false,
  });

  const ValidationSchema = Yup.object().shape({
    parameters: Yup.object().shape({
      content: Yup.string().when('instruction_type', {
        is: (value) => {
          return INSTRUCTIONS_WITH_CONTENT.includes(value);
        },
        then: () => Yup.string().required(),
        otherwise: () => Yup.string().nullable(),
      }),
    }),
    instruction_type: Yup.string().required('Povinné pole'),
  });

  const onSubmitButton = () => {
    if (!formRef?.current) return null;
    formRef.current.submitForm();
  };

  const options = data?.map((value) => {
    return { title: value.title, id: value.shortcut };
  });

  const headerOptions = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].map((key) => {
    return { title: key, id: key };
  });

  return (
    <Modal
      title={formProps ? 'Editace instrukce' : 'Vytvoření instrukce'}
      visible={visible}
      header={[]}
      footer={[
        <ModalButton key={1} action={() => onClose()}>
          Zavřít
        </ModalButton>,
        <ModalButton key={2} primary action={() => onSubmitButton()}>
          Potvrdit
        </ModalButton>,
      ]}
    >
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        initialValues={{
          instruction_type: formProps?.instruction_type || null,
          parameters: {
            content: formProps?.parameters?.content || null,
            header_type: formProps?.parameters?.header_type || null,
          },
        }}
        onSubmit={(values) => {
          onSubmit(formProps?.id, values);
        }}
      >
        {(props) => {
          const { values, setFieldValue } = props;

          return (
            <>
              <Form>
                <div>
                  <>
                    <Label name="instruction_type" title="Typ instrukce" required />
                    <Field
                      name="instruction_type"
                      value={values.instruction_type || null}
                      placeholder="Typ instrukce"
                      data={options ?? []}
                      onChange={(val) => {
                        setFieldValue('instruction_type', val);
                      }}
                      component={SelectField}
                    />
                  </>

                  {values.instruction_type === InstructionTypes.HEA && (
                    <>
                      <Label name="header_type" title="Typ nadpisu" required />
                      <Field
                        name="header_type"
                        value={values.parameters.header_type || null}
                        placeholder="Typ nadpisu"
                        data={headerOptions}
                        onChange={(val) => {
                          setFieldValue('parameters.header_type', val);
                        }}
                        component={SelectField}
                      />
                    </>
                  )}

                  {INSTRUCTIONS_WITH_CONTENT.includes(values.instruction_type) && (
                    <>
                      <Label name="parameters.content" title="Text" required />
                      <Field
                        name="parameters.content"
                        value={values.parameters.content || null}
                        placeholder="Text"
                        component={AreaField}
                      />
                    </>
                  )}
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default InstructionDialog;
