import React, { useState } from 'react';
import ModalButton from '../modal/ModalButton';
import Modal from '../modal/Modal';
import Search from 'antd/lib/input/Search';
import NavList from '../../elements/NavList';
import { debounce } from 'lodash';

const SharedModlalToAddItemFromList = ({
  visible,
  onClose,
  data,
  submit,
  title,
  onSearch,
  currentList = [],
  hasMore,
  fetchMore,
}) => {
  const [active, setActive] = useState(null);
  const prepareData = () =>
    (data || [])
      .map((item) => ({ ...item, text: item.title }))
      .filter((item) => !currentList.includes(item.id));

  const makeActive = (id) => {
    setActive(id);
  };

  const debounceSearch = debounce((e) => {
    onSearch(e);
  }, 500);

  const buttons = () => {
    return [
      <ModalButton key={1} action={() => onClose()}>
        Zavřít
      </ModalButton>,
      <ModalButton primary key={2} action={() => submit(active)}>
        Přidat
      </ModalButton>,
    ];
  };
  return (
    <Modal title={title} visible={visible} header={[]} footer={buttons()}>
      <Search placeholder="Vyhledat" onChange={debounceSearch} />
      <NavList
        data={prepareData()}
        makeActive={makeActive}
        active={active}
        hasMore={hasMore}
        fetchMoreData={fetchMore}
      />
    </Modal>
  );
};

export default SharedModlalToAddItemFromList;
