import { EditOutlined } from '@ant-design/icons';
import { Add16Regular } from '@fluentui/react-icons';
import { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import MainContainer from '../../containers/MainContainer';
import Bar from '../../elements/Bar';
import NLeftPanel from '../../shared/components/NLeftPanel';
import { apiService } from '../../utills/api';
import { SearchContext } from '../project/building/Building';
import TreeItem, { TreeItemButton } from './features/DocumentationTree';

import useGetDocumentationDetail from './hooks/getDcomentationDetail';
import useGetDocumentation from './hooks/getGetDocumentation';
import {
  useGetDocumentationNotifications,
  useGetSpecificNotifications,
} from './hooks/getNotifications';
import DocDialog from './modals/CreateDocDialog';
import PartModal from './modals/PartModal';

//todo toto freezuje appku
function addItemToTree(tree, newItem) {
  if (newItem.parent === null) {
    return [...tree, newItem];
  }

  return tree.map((item) => {
    if (item.id === newItem.parent) {
      return {
        ...item,
        children: [...item.children, newItem],
        children_number: item.children_number + 1,
      };
    } else if (item.children.length > 0) {
      return {
        ...item,
        children: addItemToTree(item.children, newItem),
      };
    }
    return item;
  });
}

function updateStatus(tree, statusData) {
  return tree?.map((item) => {
    if (item.id === statusData.documentID) {
      return {
        ...item,
        status: statusData.status,
        last_revision: {
          ...item.last_revision,
          tasks: [{ file: statusData?.file ?? '' }],
        },
      };
    } else if (item.children.length > 0) {
      return {
        ...item,
        children: updateStatus(item.children, statusData),
      };
    }
    return item;
  });
}

function updateFilter(tree, docId, filterId) {
  return tree?.map((item) => {
    if (item.id === docId) {
      return {
        ...item,
        filter: filterId,
      };
    } else if (item.children.length > 0) {
      return {
        ...item,
        children: updateFilter(item.children, docId, filterId),
      };
    }
    return item;
  });
}

const Documentation = () => {
  const [search, setSearch] = useState(null);
  const [filter, setFilter] = useState({});
  const [activeDocumentationId, setActiveDocumentationId] = useState(null);
  const [activeDocumentId, setActiveDocumentId] = useState(null);

  const { data: tree } = useGetDocumentationDetail(activeDocumentationId);
  const { data: documentation } = useGetDocumentation(activeDocumentationId);
  const { data: notifications } = useGetDocumentationNotifications(documentation?.id);

  const { data: unResolvedNotifications } = useGetSpecificNotifications(
    notifications?.map(({ notificationId }) => notificationId)
  );

  const queryKey = 'documentation';
  const queryClient = useQueryClient();
  const [docDialogOpen, setDocDialogOpen] = useState(false);
  const [partModalOpen, setPartModalOpen] = useState(false);

  const [edit, setEdit] = useState(false);
  const { current_company } = useSelector((state) => state.auth);
  const { contractId, phaseId } = useSelector((state) => state.contract);

  useEffect(() => {
    setActiveDocumentationId(null);
  }, [contractId]);

  useEffect(() => {
    if (unResolvedNotifications && tree) {
      let unresolved = [];
      queryClient.setQueryData(['documentationTree', activeDocumentationId], () => {
        let newTree = [...tree];

        unResolvedNotifications.map((statusData) => {
          newTree = updateStatus(newTree, statusData);
          console.log({ statusData });

          if (statusData?.status !== 'R' && statusData?.status !== 'E') {
            unresolved.push(statusData);
          }
        });

        return newTree;
      });

      queryClient.setQueryData(['notifications', documentation?.id], () => {
        return unresolved;
      });
    }
  }, [unResolvedNotifications, queryClient, tree]);

  const updateTreeItems = useMutation({
    mutationFn: (parent, next) => {
      return apiService.get(`/api/v1/documentation/${activeDocumentationId}/treeview/`, {
        params: { parent, page: next, page_size: 10 },
      });
    },
    onSuccess: ({ data }, parent, context) => {
      queryClient.setQueryData(['documentation', activeDocumentationId], (prev) => {
        const updatedTree = iterateOverRootOfTree(prev, parent, data);
        return updatedTree;
      });
    },
  });

  const createDocPart = useMutation({
    mutationFn: (values) => {
      return apiService.post(`/api/v1/document/`, {
        ...values,
        documentation: activeDocumentationId,
        parent: activeDocumentId,
      });
    },
    onSuccess: ({ data }) => {
      //TODO mrknout proc to freezen appku
      // queryClient.setQueryData(['documentationTree', activeDocumentationId], (oldData) => {
      //   return addItemToTree(oldData, data);
      // });

      queryClient.invalidateQueries(['documentationTree', activeDocumentationId]);
      setPartModalOpen(false);
    },
    onError: (error) => {
      NotificationManager.error('Chyba', 'Položku se nepodařilo vytvořit.');
    },
  });

  const createDoc = useMutation({
    mutationFn: (values) => {
      return apiService.post(`/api/v1/documentation/`, {
        ...values,
        company: current_company,
        contract: contractId,
        phase: phaseId,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      setDocDialogOpen(false);
    },
  });

  const editDoc = useMutation({
    mutationFn: ({ id, values }) => {
      return apiService.patch(`/api/v1/documentation/${id}/`, values);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      setDocDialogOpen(false);
      setEdit(false);
    },
  });

  const handlePrintPdf = async (id) => {
    if (!id) {
      return;
    }

    try {
      await apiService.get(`/api/v1/document/${id}/generate-pdf/`);
      queryClient.invalidateQueries(['notifications', activeDocumentationId]);
    } catch (e) {
      NotificationManager.error('Nastala chyba', e?.response?.message);
    }
  };

  const handleAddPart = (id, values) => {
    createDocPart.mutate(values);
  };

  const handleDocSubmit = (id, values) => {
    if (!id) {
      createDoc.mutate(values);
    } else {
      editDoc.mutate({ id, values });
    }
  };

  const updateItemInTree = (node, id, nextItems) => {
    if (node.id === id) {
      node.children = node.children.concat(nextItems);
    }

    if (node.children !== undefined && node.children.length > 0) {
      for (let i = 0; i < node.children.length; i++) {
        node.children[i] = updateItemInTree(node.children[i], id, nextItems);
      }
    }

    return node;
  };

  const iterateOverRootOfTree = (tree, id, dataForUpdate) => {
    let result = [];
    if (Array.isArray(tree) && tree.length > 0) {
      for (let i = 0; i <= tree.length - 1; i++) {
        result = result.concat(updateItemInTree(tree[i], id, dataForUpdate));
      }
    }

    return result;
  };

  const getClassificationChildren = (parent) => {
    const startFetchRutine = async (next) => {
      if (!next) return null;

      updateTreeItems.mutate(parent, next);
    };
    startFetchRutine(1);
  };

  const itemTrea = (item) => {
    const controls = [];

    if (item.document_type !== 'SH' && item.document_type !== 'DR') {
      controls.push(
        <TreeItemButton
          key={2}
          text="Přidat"
          onClick={() => {
            setActiveDocumentId(item.id);
            setPartModalOpen(true);
          }}
        />
      );
    } else {
      controls.push(
        <TreeItemButton
          disabled={item.status === 'G' || item.status === 'P'}
          key={1}
          onClick={() => handlePrintPdf(item.id)}
          text="Tisk"
        />
      );
    }

    if (item.children.length === 0) {
      return (
        <TreeItem
          item={item}
          key={item.id}
          type={item.document_type}
          content={item.title}
          filterId={item.filter}
          documentId={item.id}
          documentationId={activeDocumentationId}
          updateFilter={(filterId) => updateFilter(tree, item.id, filterId)}
          updateDocument={() => {
            setPartModalOpen(true);
          }}
          files={
            item.file_list
              ? item.file_list.map((ele, i) => (
                  <a
                    key={i}
                    // className="link-image-design-form"
                    href="#"
                    onClick={() => {
                      // if (ele.type === 'pdf') openInNewTab(ServerPath._currentValue + ele.pdf);
                      // else if (ele.type === 'dwg')
                      //   this.downloadFileWithURI(ServerPath._currentValue + ele.pdf, 'file');
                      // return false;
                    }}
                  >
                    {/* {IconView(ele)} */}
                  </a>
                ))
              : ''
          }
          onExpand={() => {
            getClassificationChildren(item.id);
          }}
          classification={item.classification}
          revit_UUID={item.revit_UUID}
          scale={item.type === 'D' ? item.scale : ''}
          created_on={item.type === 'D' ? item.last_version_created_on : ''}
          is_officially_released={item.type === 'D' ? item.is_officially_released : ''}
          status={item.status}
          tasks={item.last_revision?.tasks}
          last_revision={item.type === 'D' ? item.last_revision : ''}
          last_version={item.type === 'D' ? item.last_version : ''}
          controls={controls}
        />
      );
    }

    // let params = makeContent(item);
    return (
      <TreeItem
        item={item}
        key={item.id}
        type={item.document_type}
        documentId={item.id}
        content={item.title}
        documentationId={activeDocumentationId}
        filterId={item.filterId}
        updateFilter={(filterId) => updateFilter(tree, item.id, filterId)}
        updateDocument={() => {
          setPartModalOpen(true);
        }}
        onExpand={() => {
          getClassificationChildren(item.id);
        }}
        status={item.status}
        tasks={item.last_revision?.tasks}
        controls={controls}
        canHide
        open
      >
        {item.children.map((child) => itemTrea(child))}
      </TreeItem>
    );
  };

  const makeTree = (data) => {
    return data?.map((ele) => itemTrea(ele));
  };

  return (
    <SearchContext.Provider
      value={{
        search,
        filter,
        setSearch,
        setFilter,
      }}
    >
      <MainContainer>
        <div className="flex w-full">
          <NLeftPanel
            queryKey={queryKey}
            makeActive={setActiveDocumentationId}
            active={activeDocumentationId}
            onSearch={() => {}}
            addItem={() => setDocDialogOpen(true)}
            onFilter={() => {}}
            defaultFilterValues={{ is_active: [true] }}
            title="Dokumentace"
            permission={2}
            filteredValues={{
              is_active: [
                { value: true, title: 'Aktivní' },
                { value: false, title: 'Neaktivní' },
              ],
            }}
          />
          <div className="w-full bg-color4">
            <Bar
              text="Detail"
              content={[
                <Add16Regular key={0} onClick={() => setPartModalOpen(true)} />,
                <EditOutlined
                  key={1}
                  onClick={() => {
                    setEdit(true);
                    setDocDialogOpen(true);
                  }}
                />,
              ]}
            />
            {activeDocumentationId && makeTree(tree)}
          </div>
        </div>
      </MainContainer>

      <DocDialog
        visible={docDialogOpen}
        onClose={() => {
          setDocDialogOpen(false);
          setEdit(false);
        }}
        formProps={edit ? documentation : null}
        onSubmit={handleDocSubmit}
      />

      {partModalOpen && (
        <PartModal
          visible={partModalOpen}
          onSubmit={handleAddPart}
          documentationId={activeDocumentationId}
          documentId={activeDocumentId}
          onClose={() => {
            setActiveDocumentId(null);
            setPartModalOpen(false);
          }}
        />
      )}
    </SearchContext.Provider>
  );
};

export default Documentation;
