import React from 'react';
import { Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

const FormItem = Form.Item;

const getNestedValue = (obj, path) => path.split('.').reduce((acc, key) => acc?.[key], obj);

export const AreaField = ({ field, form: { touched, errors, submitCount }, ...props }) => {
  const errorValue = getNestedValue(errors, field.name);
  const touchedValue = getNestedValue(touched, field.name);
  const errorMsg =
    (touchedValue && errorValue) || (errorValue && submitCount > 0) ? errorValue : null;

  return (
    <FormItem
      label={props.label}
      help={errorMsg}
      hasFeedback={!!errorValue}
      validateStatus={errorValue && (touchedValue || submitCount > 0) && 'error'}
    >
      <TextArea {...field} {...props} rows={5} showCount={!!props.limit} maxLength={props.limit} />
    </FormItem>
  );
};
