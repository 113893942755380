import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import InfiniteScroll from 'react-infinite-scroller';
import { Spin } from 'antd';

const NavList = ({ makeActive, data, active, fetchMoreData, hasMore }) => {
  const [activeItem, setActiveItem] = useState(active);

  useEffect(() => {
    setActiveItem(active);
  }, [active]);

  const _makeActive = (id) => {
    setActiveItem(id);
    makeActive(id);
  };

  return Array.isArray(data) !== true
    ? ''
    : data.length > 0 && (
        <InfiniteScroll
          pageStart={0}
          loadMore={fetchMoreData}
          hasMore={hasMore}
          loader={
            <span className="w-full flex justify-center mt-4">
              <Spin />
            </span>
          }
          useWindow={false}
          className="h-auto"
        >
          {data.map(
            (item) =>
              item && (
                <span
                  key={item.id}
                  onClick={() => _makeActive(item.id)}
                  className={classNames(
                    'inline-block list-none w-full min-h-[30px] align-middle hover:bg-color2 hover:text-white cursor-pointer leading-none text-sm text-gray-500 py-[5px] px-2 border-b border-neutral-200 break-all',
                    {
                      'bg-color2 text-white': item.id === activeItem,
                    }
                  )}
                >
                  {item.title}
                </span>
              )
          )}
        </InfiniteScroll>
      );
};

export default NavList;
