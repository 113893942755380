import React, { useEffect, useState } from 'react';
import ModalButton from '../../../../../shared/modal/ModalButton';
import Modal from '../../../../../shared/modal/Modal';

import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from 'antd';
import classNames from 'classnames';
import { LICENCE } from '../../../../../constants';
import { useMutation } from 'react-query';
import { apiService } from '../../../../../utills/api';
import { NotificationManager } from 'react-notifications';
import { loadProfile } from '../../../../../store/actions/auth';

const ChangeLicenceModal = ({ visible, onClose }) => {
  const detail = useSelector((state) => state?.company?.detail);
  const [licence, setLicence] = useState(null);

  useEffect(() => {
    setLicence(detail?.licence?.licence_type);
  }, [detail]);

  const activeFree = LICENCE[licence]?.name === 'free';

  const dispatch = useDispatch();

  const changeLicence = useMutation({
    mutationFn: () => {
      return apiService.post(`/api/v1/company/${detail.id}/licence-plan-change/`, {
        licence_type: licence,
      });
    },
    onSuccess: () => {
      dispatch({ type: 'CHANGE_LICENCE', data: licence });
      dispatch(loadProfile);
      NotificationManager.success('Licence úspěšně změněna');
      onClose();
    },
    onError: (error) => {
      NotificationManager.error(error?.response?.data?.detail);
    },
  });

  if (!detail?.licence) {
    return null;
  }

  const footer = () => {
    return [
      <ModalButton key={1} action={() => onClose()}>
        Zavřít
      </ModalButton>,
      <ModalButton
        primary
        disabled={licence === detail?.licence?.licence_type}
        key={2}
        action={() => changeLicence.mutate()}
      >
        {activeFree
          ? 'Přejít na verzi zdarma'
          : `Přejít na placenou verzi ${detail.licence.monthly_price_no_vat}/měsíc`}
      </ModalButton>,
    ];
  };

  const handleChangeLicence = (value) => {
    setLicence(value);
  };

  return (
    <Modal title={'Výběr licence'} visible={visible} footer={footer()}>
      <div className="flex gap-3 w-full items-stretch">
        <div
          onClick={() => handleChangeLicence('free')}
          className={classNames(
            'rounded-lg border border-indigo-300 shadow-lg shadow-indigo-300/75 flex-1 flex flex-col h-48 p-4 text-indigo-500 justify-between cursor-pointer',
            { 'shadow-indigo-500/75  text-white bg-indigo-400 ': activeFree }
          )}
        >
          <h2
            className={classNames('font-semibold mx-auto text-indigo-500', {
              '!text-white': activeFree,
            })}
          >
            Zdarma
          </h2>

          <div className="grow" />

          <ul className="shrink-0">
            <li>
              <Checkbox
                className={classNames('text-xs text-indigo-500  cursor-text', {
                  '!text-white': activeFree,
                })}
                checked
              >
                Až 3 projekty
              </Checkbox>
            </li>
            <li>
              <Checkbox
                className={classNames('text-xs text-indigo-500  cursor-text', {
                  '!text-white': activeFree,
                })}
                checked
              >
                Neomezeně uživatelů
              </Checkbox>
            </li>
          </ul>
        </div>

        <div
          onClick={() => handleChangeLicence('pay')}
          className={classNames(
            'rounded-lg border border-indigo-300 shadow-lg shadow-indigo-300/75 flex-1 flex flex-col h-48 p-4 text-indigo-500 justify-between cursor-pointer',
            { 'shadow-indigo-500/75  text-white bg-indigo-400 ': !activeFree }
          )}
        >
          <h2
            className={classNames('font-semibold mx-auto text-indigo-500', {
              '!text-white': !activeFree,
            })}
          >
            Placená
          </h2>

          <div
            className={classNames('flex justify-center flex-col items-center mt-4', {
              'text-white': !activeFree,
            })}
          >
            <span className="text-center text-2xl font-bold">
              {`${detail.licence.price_per_employee_no_vat} KČ`}
            </span>
            <span className="text-xs">{'cena za osobu'}</span>
          </div>

          <ul className="mt-4">
            <li>
              <Checkbox
                className={classNames('text-xs text-indigo-500  cursor-text', {
                  '!text-white': !activeFree,
                })}
                checked
              >
                Neomezeně projektů
              </Checkbox>
            </li>
            <li>
              <Checkbox
                className={classNames('text-xs text-indigo-500  cursor-text', {
                  '!text-white': !activeFree,
                })}
                checked
              >
                Neomezeně uživatelů
              </Checkbox>
            </li>
          </ul>
        </div>
      </div>
    </Modal>
  );
};

export default ChangeLicenceModal;
