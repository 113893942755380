import React, { useState, Suspense, createContext } from 'react';
import { NotificationManager } from 'react-notifications';
import {
  getBuildingDetail,
  getDetailFromPoint,
  updateEtape,
} from '../../../services/projectService';
import MainContainer from '../../../containers/MainContainer';
import BuildingDetailView from './components/BuildingDetailView';
import { useSelector } from 'react-redux';
import NLeftPanel from '../../../shared/components/NLeftPanel';
import { useMutation, useQueryClient } from 'react-query';
import { apiService } from '../../../utills/api';

const BuildingModal = React.lazy(() => import('./components/modals/BuildingModal'));

export const SearchContext = createContext();

function Building() {
  const permission = useSelector((state) => state.auth.pagePermission);
  const { modelId, contractId } = useSelector((state) => state.contract);
  const [visible, setVisible] = useState(false);
  const [detail, setDetail] = useState({});
  const [search, setSearch] = useState(null);
  const [filter, setFilter] = useState({ has_etape: [true] });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const queryClient = useQueryClient();
  const queryKey = 'building';

  const createBuilding = useMutation({
    mutationFn: (values) => {
      return apiService.post(`/api/v1/building/`, {
        ...values,
        model: modelId,
        contract: contractId,
      });
    },
    onSuccess: ({ data }) => {
      NotificationManager.success('Položka úspěšně vytvořena');
      queryClient.invalidateQueries('building');
      getDetail(data.id);
      setVisible(false);
      setIsSubmitting(false);
    },
    onError: () => {
      NotificationManager.error('Něco se pokazilo');
      setIsSubmitting(false);
    },
  });

  const editBuilding = useMutation({
    mutationFn: ({ id, values }) => {
      return updateEtape(id, values);
    },
    onSuccess: ({ data }, { id }) => {
      queryClient.invalidateQueries('building');
      NotificationManager.success('Položka úspěšně editována');
      const index = detail.etape_list.findIndex((ele) => ele.id === id);
      const etapeList = [...detail.etape_list];
      etapeList[index] = data;
      if (index > -1) {
        setDetail({ ...detail, etape_list: etapeList });
      }
      setVisible(false);
    },
  });

  const getDetail = async (id) => {
    try {
      const response = await getBuildingDetail(id);
      setDetail(response.data);
    } catch (e) {
      NotificationManager.error('Chyba', 'Omlouváme se, něco se pokazilo');
    }
  };

  const updateEtapeCallback = async (id, values) => {
    editBuilding.mutate({ id, values });
  };

  const handleSubmit = async (
    id,
    { latitude, longitude, title, building_title, description } = {}
  ) => {
    setIsSubmitting(true);
    const dataFromGeoCode = (await getDetailFromPoint(latitude, longitude)).data.results[0];

    if (!dataFromGeoCode) return;

    const { address_components } = dataFromGeoCode;

    const googleGeoCodeData = {};
    address_components.forEach((ele) => {
      ele.types.forEach((_type) => {
        if (!googleGeoCodeData[_type]) {
          googleGeoCodeData[_type] = ele?.long_name ?? '';
        }
      });
    });

    const req = {
      latitude: latitude.substring(0, 9),
      longitude: longitude.substring(0, 9),
      identity_data: {
        street: `${googleGeoCodeData?.route ?? ''} ${googleGeoCodeData?.street_number ?? ''}`,
        locality: googleGeoCodeData?.locality ?? '',
        sub_locality: googleGeoCodeData?.sublocality ?? '',
        postal_code: googleGeoCodeData?.postal_code ?? '',
        ruian_id: '',
      },
      etape: { title, building_title, description },
    };
    try {
      createBuilding.mutate(req);
    } catch (e) {
      NotificationManager.error('Chyba', 'Omlouváme se, něco se pokazilo');
    }
  };

  const onSearch = async (e) => {
    setSearch(e?.target?.value);
  };

  const onFilter = async (value) => {
    setFilter(value);
  };

  return (
    <SearchContext.Provider
      value={{
        search,
        filter,
        setSearch,
        setFilter,
      }}
    >
      <MainContainer>
        <NLeftPanel
          queryKey={queryKey}
          makeActive={getDetail}
          active={detail.id}
          onSearch={onSearch}
          addItem={() => setVisible(true)}
          onFilter={onFilter}
          defaultFilterValues={{ has_etape: [true] }}
          title="Stavby"
          permission={permission}
          filteredValues={{
            has_etape: [
              { value: true, title: 's etapou' },
              { value: false, title: 'bez etapy' },
            ],
          }}
        />
        {visible && (
          <Suspense fallback={<div>Loading...</div>}>
            <BuildingModal
              submitForm={handleSubmit}
              title="Vytvořit stavbu"
              visible={visible}
              onClose={() => setVisible(false)}
              isSubmitting={isSubmitting}
            />
          </Suspense>
        )}
        <BuildingDetailView
          data={detail}
          updateEtape={updateEtapeCallback}
          updateDetail={() => getDetail(detail.id)}
        />
      </MainContainer>
    </SearchContext.Provider>
  );
}

export default Building;
